<!--  -->
<template>
    <div class="sidebarBox">
        <div class="sidebar" @click="togglewide" v-if="togglewideVal == true">
            <i class="el-icon-arrow-left"></i>
        </div>
        <div class="sidebarcont" @click="togglewide" 
        :class="togglewideVal== true?'togglewidestyle' :'newtogglewidestyle'">
            <div class="sidebarmenu">
                <div class="sidebarmenuitem"></div>
            </div>
            <el-popover placement="bottom-start"
                title="" width="90" trigger="hover"
                content="Email:Weiqiuling@junkotech.com">
                <div class="sidebaruser" slot="reference">
                    <img src="../assets/some/email.png" alt="邮箱">
                    <div>Email</div>
                </div>
            </el-popover>
            <el-popover placement="bottom-start"
                title="" width="90" trigger="hover"
                content="Whatsapp:+86-21-62377015">
                <div class="sidebaruser" slot="reference">
                    <img src="../assets/some/phone.png" alt="电话">
                    <div>Whatsapp</div>
                </div>
            </el-popover>
        </div>
    </div>
  </template>
  <script>
    export default {
        name: "sidebar",
        components: {},
        data() {
            return {
                togglewideVal: true,
            }
        },
        computed: {},// 监听属性 类似于data概念
        watch: {},
        filters: {},
        methods: {
            togglewide() {
                this.togglewideVal =!this.togglewideVal
            },

        },//方法集合
        created() {},// 生命周期 - 创建完成（可以访问当前this实例）
        mounted() {},//生命周期 - 挂载完成（可以访问DOM元素）
        beforeCreate() {}, // 生命周期 - 创建之前
        beforeMount() {}, // 生命周期 - 挂载之前
        beforeUpdate() {}, // 生命周期 - 更新之前
        updated() {}, // 生命周期 - 更新之后
        beforeDestroy() {}, // 生命周期 - 销毁之前
        destroyed() {}, // 生命周期 - 销毁完成
        activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
    };
  </script>
  <style lang="less" scoped src="@/styles/pc/sidebar.less"></style>
  <style lang="less">

  </style>
  
  
  
  
  