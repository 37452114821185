<!--  -->
<template>
    <div class="bottom">
        <div class="bottomcont">
 
            <div class="bottomcontnew">
                <img src="../assets/some/logo2.png" />
                <div>JUNKO NEW ENERGY</div>
            </div>
            <div class="bottomcontfont">
                Shanghai Junko New Energy Technology Co., Ltd., is a high-tech enterprise specializing in the research and development, production, and sales of induction heating technology and semiconductor materials.
            </div>


        </div>
        <el-divider></el-divider>
        <div class="bottomcontbtn">Copyright©Shanghai Junko New Energy Technology Co.LtdReserved.</div>
    </div>
  </template>
  <script>
    export default {
        name: "bottom",
        components: {},
        data() {
            return {}
        },
        computed: {},// 监听属性 类似于data概念
        watch: {},// 监控data中的数据变化
        filters: {},
        methods: { 
            navPage(val) {
                if(val == '1') {
                    this.$router.push('/homewe')
                }
                if(val == '2') {
                    this.$router.push('/homeChan')
                }
                if(val == '3') {
                    this.$router.push('/homeYing')
                }
                if(val == '4') {
                    this.$router.push('/homeWo')
                }
                if(val == '6') {
                    this.$router.push('/home')
                }
            },
        },//方法集合
        created() {},// 生命周期 - 创建完成（可以访问当前this实例）
        mounted() {},//生命周期 - 挂载完成（可以访问DOM元素）
        beforeCreate() {}, // 生命周期 - 创建之前
        beforeMount() {}, // 生命周期 - 挂载之前
        beforeUpdate() {}, // 生命周期 - 更新之前
        updated() {}, // 生命周期 - 更新之后
        beforeDestroy() {}, // 生命周期 - 销毁之前
        destroyed() {}, // 生命周期 - 销毁完成
        activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
    };
  </script>
  <style lang="less" scoped src="@/styles/pc/bottom.less"></style>
  <style lang="less" scoped>

  </style>
  
  
  
  