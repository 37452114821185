<!--  -->
<template>
    <div class="headBox">
        <div class="homeTop">
            <div class="homeTopCont">
                <span class="homeTopContFont">Welcome to JUNKO NEW ENERGY！</span>
                <div class="homeTopContRig">
                    <div class="homeTopCont-left">
                        <!-- <img src="../assets/new/1.png" /> -->
                        <!-- <el-dropdown @change="langueFun">
                            <span class="el-dropdown-link">
                            English<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>English</el-dropdown-item>
                                <el-dropdown-item>한국어</el-dropdown-item>
                            </el-dropdown-menu> 
                        </el-dropdown> -->
                    </div>
                    <div class="homeTopCont-rig">
                        Service hotline：+86-21-62377015
                    </div>
                </div>

            </div>
        </div>
        <div class="head">
            <div class="headcontent">
                <div class="headcontentlft">
                    
                    <img src="../assets/some/logo2.png"  mode="scaleToFill" />
                    <!-- <span class="headcontentlftSpan" @click="navHome">JUNKO NEW ENERGY</span> -->
                </div>
                <el-menu mode="horizontal" @select="handleSelect" text-color="#f2f2f2"
                    background-color="#4d9ef1" active-text-color="#ffffff"
                    :default-active="$route.path" ref='menu' router
                    :uniqueOpened=true>
                    <el-menu-item index="/home">Home</el-menu-item>
                    <el-menu-item index="/homewe">About Us</el-menu-item>
                    <el-submenu index="2">
                        <template slot="title">Product Center</template>
                        <el-menu-item index="/homeChan" :route="{name:'homeChan',query:{state:1}}"><i class="el-icon-menu"></i>Equipment</el-menu-item>
                        <el-menu-item index="/homeChan" :route="{name:'homeChan',query:{state:2}}"><i class="el-icon-s-promotion"></i>SIC</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="/homeYing">Application Cases</el-menu-item>
                    <el-menu-item index="/homeWo">Contact Us</el-menu-item>
                </el-menu>
            </div>
        </div>
    </div>
  </template>
  <script>
    export default {
        name: "headNav",
        components: {},
        data() {
            return {
                activeIndex: '1',
                navMode:'',

                language: 'en',
            }
        },
        computed: {},// 监听属性 类似于data概念
        watch: {},
        filters: {},
        methods: {
            // 导航选择
            handleSelect(key, keyPath) {
                // console.log('导航选择11>>>' + JSON.stringify(key));
                console.log('导航选择22>>>' + JSON.stringify(keyPath));
                this.$bus.$emit('app-reload')
                this.$router.push(key).catch(err => { console.log(err) })
            },
            navHome() {
                this.$router.push('/home').catch(err => { console.log(err) })
            },
        },//方法集合
        created() {},// 生命周期 - 创建完成（可以访问当前this实例）
        mounted() {},//生命周期 - 挂载完成（可以访问DOM元素）
        beforeCreate() {}, // 生命周期 - 创建之前
        beforeMount() {}, // 生命周期 - 挂载之前
        beforeUpdate() {}, // 生命周期 - 更新之前
        updated() {}, // 生命周期 - 更新之后
        beforeDestroy() {}, // 生命周期 - 销毁之前
        destroyed() {}, // 生命周期 - 销毁完成
        activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
    };
  </script>
  <style lang="less" scoped src="@/styles/pc/head.less"></style>
  <style lang="less" >
    .head {
        .el-menu.el-menu--horizontal {
            border: 0;
        }
        .el-menu {
            background-color: rgba(0, 0, 0, 0);
        }
        .el-submenu__title i {
            color: #fff !important;
        }
        .el-menu-item i {
            color: #fff !important;
        }
    }
    .headcontent {
        .el-icon-arrow-down {
            color: #fff !important;
        }
        .el-menu {
            width: 80%;
            display: contents;
        }
        .el-menu--horizontal>.el-menu-item {
            font-size: 16px !important;
        }
        .el-submenu__title {
            font-size: 16px !important;
        }
    }
  </style>
  
  
  
  